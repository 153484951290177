<template>
  <div>
    <v-breadcrumbs
      class="pt-3 pl-7"
      :items="[{ text: 'Size Families', exact: true,  to: { name: 'sizes' } }, { text: oldFamily.name }]"
    >
    </v-breadcrumbs>
    <v-row
      class="mt-0"
    >

      <v-col
        cols="12"
        md="5"
      >

        <v-card
          flat
          class="mx-3 pb-9"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Size Family</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="editFamilyClick()"
              :disabled="!user.can('size-family.update')"
            >
              <v-icon color="secondary" small>fas fa-edit</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>

            <dl class="row table mt-5">
              <dt class="col-5">Name:</dt>
              <dd class="col-7">{{ oldFamily.name }}</dd>
            </dl>

          </v-card-text>

          <size-family-dialog
            v-model="familyDialog"
            :attributes="family"
            :errors="familyErrors"
            @save="saveFamilyClick()"
          ></size-family-dialog>

        </v-card>


      </v-col>


      <v-col
        cols="12"
        md="7"
      >
        <v-card
          flat
          class="mx-3 pb-9"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Sizes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              background-color="primary"
              v-model="filter.query"
              label="Search..."
              single-line
              hide-details
              solo
              @keyup="searchKeyUp()"
            ></v-text-field>
            <v-btn
              icon
              size="secondary"
              @click="newSizeClick()"
              :disabled="!user.can('size.create')"
            >
              <v-icon small>fas fa-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-data-table
            :class="{ 'mt-5': true, 'clickable': user.can('size.update') }"          
            :headers="sizeHeaders"
            :items="sizes"
            :loading="isSearching"
            :options.sync="options"
            :server-items-length="totalCount"
            :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
            @click:row="rowSizeClick"
          >
          </v-data-table>

          <size-dialog
            v-model="sizeDialog"
            :attributes="size"
            :errors="sizeErrors"
            :families="families"
            @save="saveSizeClick()"
          ></size-dialog>

        </v-card>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import { inject, ref, watch } from 'vue'
import SizeFamilyDialog from '@/components/SizeFamilyDialog'
import SizeDialog from '@/components/SizeDialog'
import { useRouter, useRoute } from 'vue-router/composables'

export default {
  components: {
    SizeFamilyDialog,
    SizeDialog,
  },
  setup() {
    const api = inject('api')
    const { user } = api
    const router = useRouter()
    const route = useRoute()

    const familyModel = api.model('size-families/:id', {
      params: {
      },
    })

    const { attributes: family, errors: familyErrors, oldAttributes: oldFamily } = familyModel
    const familyDialog = ref(false)

    const familyCollection = api.collection('size-families')
    const { items: families } = familyCollection.all()


    function saveFamilyClick() {
      familyModel.save().then(() => {
        familyCollection.all()
        familyDialog.value = false
      })
    }

    const sizeCollection = api.collection('sizes', {
      params: {
      },
      filter: {
        size_family_id: 'eq',
        query: 'query:code,name',
      },
    })

    const { items: sizes, filter, options, totalCount, isSearching } = sizeCollection

    const sizeHeaders = [
      { text: 'Code', value: 'code', },
      { text: 'Name', value: 'name' },
    ]

    const searchKeyUp = sizeCollection.debounceSearch(300)

    const sizeModel = api.model('sizes/:id')

    const { attributes: size, errors: sizeErrors } = sizeModel
    const sizeDialog = ref(false)

    function newSizeClick() {
      sizeModel.clear().setAttribute('size_family_id', family.value.id)
      sizeDialog.value = true
    }

    function saveSizeClick() {
      sizeModel.save().then(() => {
        sizeCollection.search()
        sizeDialog.value = false
      })
    }

    function rowSizeClick(item) {
      if (user.can('size.update')) {
        sizeModel.clear().populate(item)
        sizeDialog.value = true
      }
    }

    function editFamilyClick() {
      familyModel.reset()
      familyDialog.value = true
    }

    watch(() => route, ({ params }) => {
      if (params.id) {
        familyModel.find(params.id).catch(() => {
          router.push({
            name: 'sizes',
          })
        })
        sizeCollection.setFilter('size_family_id', params.id).search()
      }
    }, { immediate: true })

    return {
      user: api.user,
      family,
      familyErrors,
      oldFamily,
      familyDialog,
      editFamilyClick,
      saveFamilyClick,
      sizes,
      filter,
      options,
      totalCount,
      isSearching,
      sizeHeaders,
      size,
      sizeErrors,
      sizeDialog,
      newSizeClick,
      saveSizeClick,
      rowSizeClick,
      families,
      searchKeyUp,
    }

  },
}
</script>

<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

dl.table dt, dl.table dd {
  padding-top: 2px;
  padding-bottom: 2px;
}


</style>